*, ::after, ::before {
    box-sizing: border-box;
  }
  body {
    font-family: 'Karla', sans-serif;
    margin: 0;
    padding: 0;
    background: #f2f8fd;
  }
  .mobile-container a{
    color: #0096c7;
  }
  h1, h2, h3{
    margin: 0;
    color: #000;
    font-weight: 700;
  }
  .mobile-container{
    padding: 40px 25px 25px;
  }
  .mobile-container h1{
    font-size: 28px;
    margin-bottom: 25px;
    text-align: center;
    color: #fc0005;
  }
  .mobile-container h2{
    font-size: 22px;
    text-align: center;
    margin: 15px 0 30px;
  }
  .mobile-container h3{
    font-size: 18px;
    margin-bottom: 6px;
  }
  .mobile-container p {
    font-size: 15px;
    color: #333;
    line-height: 24px;
    margin: 0 0 10px;
  }
  .mobile-container ul{
    padding: 0 0 0 15px;
    margin: 0 0 15px;
  }
  .mobile-container ul li {
    font-size: 15px;
    color: #333;
    line-height: 24px;
    margin: 0 0 10px;
  }
  .mobile-container .logo{
    text-align: center;
    margin-bottom: 20px;
  }
  .mobile-container .logo img{
    width: 86px;
  }
  .trash{
    text-align: center;
    margin-bottom: 15px;
  }
  .delete-wrap{
    border-radius: 10px;
    background: #e3f8fd;
    border: 1px solid #0077B6;
    padding: 30px 20px;
    text-align: center;
    margin-top: 30px;
  }
  .delete-wrap h4 {
    margin: 0 0 20px;
  }
  .btn-delete{
    width: 100%;
    background: #0077B6;
    border: 0;
    color: #fff;
    font-size: 15px;
    border-radius: 6px;
    padding: 15px 10px;
    font-weight: 700;
    cursor: pointer;
  }
  .form-sec .form-group{
    margin-bottom: 20px;
  }
  .form-sec .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
  }
  .form-sec .form-group .form-input{
    border-radius: 6px;
    border: 1px solid #d2d2d2;
    width: 100%;
    height: 40px;
    padding: 8px 10px;
    font-size: 14px;
    outline: none !important;
  }
  .captcha{
    margin-bottom: 15px;
    display: inline-block;
  }

  .error {
    color: red;
    font-size: 14px;
    font-weight: bold;
  }
  .success{
    color: #20935e;
    font-size: 14px;
    font-weight: bold;
  }


.submit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}


  
  @media only screen and (min-width: 767px) {
    .mobile-container{
      max-width: 450px;
      margin: auto;
    }
  }
  