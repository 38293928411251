.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
  }
  

  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.25rem;
    color: #6c757d;
  }
  
  .back-to-home-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #20935e;
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-to-home-button:hover {
    background-color: #176f46;
  }